<template>
  <div>
    <div class="img_box">
      <img class="" src="../../assets/50.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
    };
  },
  computed: {},
  mounted() {},
  methods: {}
}

</script>
<style lang='scss'>
.img_box{
  img {
    display: block;
    width: 100%;
  }
}
</style>